<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" style="width:700px; max-width:80vw;">
            <q-card-section>
                <div class="text-center text-h6">미션 수정</div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <q-timeline color="primary">
                    <q-timeline-entry>
                        <template v-slot:title>
                            <div class="ft14">반 공통 미션</div>
                            <div class="ft12">반미션은 반 학생 전체에 수정됨</div>
                        </template>
                        <q-banner class="mission bg-grey-2 q-my-sm" :key="m.id" 
                                  v-for="(m,idx) in class_mission_list">
                            <table class="w100p">
                            <tbody>
                                <tr>
                                    <td class="index">
                                        <div class="pt3">
                                            {{idx+1}}
                                        </div>
                                    </td>
                                    <td class="mission_type">
                                        <q-select outlined emit-value map-options 
                                                  class="bg-white"
                                                  v-model="m.mission_type" 
                                                  :options="mission_type_options" >
                                        </q-select>
                                    </td>
                                    <td class="description" colspan="2">
                                        <q-input outlined class="bg-white"
                                                 v-model="m.description" />
                                    </td>
                                    <td class="button">
                                        <q-btn outline class="mr2" label="지시추가" padding="2px 8px" 
                                               @click="add_todo(m)"/>
                                        <q-btn outline label="삭제" padding="2px 8px" 
                                               :disable="idx===0"
                                               @click="delete_class_mission(m)" />
                                    </td>
                                </tr>
                                <tr :key="t.id" v-for="(t, t_idx) in m.todo_list">
                                    <td></td>
                                    <td>
                                        <div class="pt3">
                                            {{idx+1}}-{{t_idx+1}}
                                        </div>
                                    </td>
                                    <td class="w100px">
                                        <q-select outlined emit-value map-options 
                                                  class="bg-white"
                                                  v-model="t.target_type" 
                                                  :options="target_type_options" >
                                        </q-select>
                                    </td>
                                    <td>
                                        <q-input outlined class="bg-white"
                                                 v-model="t.description" />
                                    </td>
                                    <td class="button">
                                        <q-select outlined emit-value map-options 
                                                  class="d-inline-block bg-white w110px mr2"
                                                  v-model="t.coach_type" 
                                                  :options="coach_type_options" >
                                        </q-select>
                                        <q-btn outline label="삭제" padding="2px 8px" 
                                               @click="delete_todo(m, t.id)"/>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </q-banner>
                        <div class="text-center mt2">
                            <q-btn color="primary" label="미션추가" padding="2px 12px"
                                   @click="add_class_mission" ></q-btn>    
                        </div>
                    </q-timeline-entry>
                    <q-timeline-entry>
                        <template v-slot:title>
                            <div class="ft14">학생 개별 미션</div>
                        </template>
                        <q-banner class="mission bg-grey-2 q-my-sm" :key="m.id" 
                                  v-for="(m,idx) in mission_list">
                            <table class="w100p">
                            <tbody>
                                <tr>
                                    <td class="index">
                                        <div class="pt3">
                                            {{idx+1}}
                                        </div>
                                    </td>
                                    <td class="mission_type">
                                        <q-select outlined emit-value map-options 
                                                  class="bg-white"
                                                  v-model="m.mission_type" 
                                                  :options="mission_type_options" >
                                        </q-select>
                                    </td>
                                    <td class="description" colspan="2">
                                        <q-input outlined class="bg-white"
                                                 v-model="m.description" />
                                    </td>
                                    <td class="button">
                                        <q-btn outline class="mr2" label="지시추가" padding="2px 8px" 
                                               @click="add_todo(m)"/>
                                        <q-btn outline label="삭제" padding="2px 8px" 
                                               @click="delete_student_mission(m)" />
                                    </td>
                                </tr>
                                <tr :key="t.id" v-for="(t, t_idx) in m.todo_list">
                                    <td></td>
                                    <td>
                                        <div class="pt3">
                                            {{idx+1}}-{{t_idx+1}}
                                        </div>
                                    </td>
                                    <td class="w100px">
                                        <q-select outlined emit-value map-options 
                                                  class="bg-white"
                                                  v-model="t.target_type" 
                                                  :options="target_type_options" >
                                        </q-select>
                                    </td>
                                    <td>
                                        <q-input outlined class="bg-white"
                                                 v-model="t.description" />
                                    </td>
                                    <td class="button">
                                        <q-select outlined emit-value map-options 
                                                  class="d-inline-block bg-white w110px mr2"
                                                  v-model="t.coach_type" 
                                                  :options="coach_type_options" >
                                        </q-select>
                                        <q-btn outline label="삭제" padding="2px 8px" 
                                               @click="delete_todo(m, t.id)"/>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </q-banner>
                        <div class="text-center mt2">
                            <q-btn color="primary" label="미션추가" padding="2px 12px"
                                   @click="add_student_mission" ></q-btn>    
                        </div>
                    </q-timeline-entry>
                    <q-timeline-entry>
                        <div class="text-center q-gutter-x-sm">
                            <q-btn color="grey" label="취소" outline v-close-popup></q-btn>
                            <q-btn @click="save_data" color="primary" label="미션 저장"></q-btn>
                        </div>
                    </q-timeline-entry>
                </q-timeline>

            </q-card-section>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
export default {
    components : {
    },
    data : function() {
        return {
            is_open : false,
            callback : null,
            user_id : 0,
            class_id : 0,
            class_inst_id : 0,
            class_work_id : 0,
            form: {
                class_mission : '',
                mission : '',
            },
            class_mission_list : [],
            mission_list : [],
            mission_type_options : [
                { label : '수업', value: 'class' },
                { label : '숙제', value: 'homework' },
                { label : '준비', value: 'prepare' },
            ],
            target_type_options  : [
                { label : '담임', value: 'class_teacher' },
                { label : '강사', value: 'teacher' },
                { label : '학생', value: 'student' },
            ],
            coach_type_options  : [
                { label : 'Pass/Fail', value: 'pass_fail' },
                { label : '자유입력', value: 'free' },
                { label : '없음', value: '' },
            ],
        };
    },
    computed : {
        date_format : function() {
            return this.$store.state.date_format;
        },
        
    },
    methods : {
        /* open */
        open : function(work_data, callback) {
            this.set_data(work_data);
            this.callback = callback;
            this.is_open = true;
        },

        /* set */
        set_data(work_data) {
            this.user_id = work_data.user_id;
            this.class_id = work_data.class_id;
            this.class_inst_id = work_data.class_inst_id;
            this.class_work_id = work_data.class_work_id;
            this.form.class_mission = work_data.class_mission;
            this.form.mission = work_data.mission;
            let class_mission_list = [];
            try {
                class_mission_list = JSON.parse(work_data.class_mission);
            } catch(e) {
                class_mission_list = [];
            }
            this.class_mission_list = class_mission_list;
            if(this.class_mission_list.length===0) {
                this.add_class_mission();
            }
            let mission_list = [];
            try {
                mission_list = JSON.parse(work_data.mission);
            } catch(e) {
                mission_list = [];
            }
            this.mission_list = mission_list;
        },

        /* mission */
        add_class_mission() {
            let last_id = 0;
            this.class_mission_list.forEach(m =>{
                if(m.id>last_id) { last_id = m.id; }
            });
            let mission = {
                'id' : last_id + 1,
                'description': '',
                'mission_type' : 'class',
                'sort_order' : 0,
                'todo_list' : [],
            };
            this.class_mission_list.push(mission);
        },
        delete_class_mission(mission) {
            this.class_mission_list = this.class_mission_list.filter((m)=>{
                return (m.id!==mission.id);
            });
        },

        add_student_mission() {
            let last_id = 0;
            this.mission_list.forEach(m =>{
                if(m.id>last_id) { last_id = m.id; }
            });
            let mission = {
                'id' : last_id + 1,
                'description': '',
                'mission_type' : 'class',
                'sort_order' : 0,
                'todo_list' : [],
            };
            this.mission_list.push(mission);
        },
        delete_student_mission(mission) {
            this.mission_list = this.mission_list.filter((m)=>{
                return (m.id!==mission.id);
            });
        },

        add_todo(mission) {
            let last_id = 0;
            mission.todo_list.forEach(m =>{
                if(m.id>last_id) { last_id = m.id; }
            });
            let todo = {
                'id' : last_id + 1,
                'description': '',
                'coach_type' : '',
                'target_type' : 'student',
            };
            mission.todo_list.push(todo);
        },
        delete_todo(mission, idx) {
            mission.todo_list = mission.todo_list.filter((t)=>{
                return (t.idx!==idx);
            });
        },
        
        /* handle */
        save_data : function() {
            let vm = this;
            let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/class_insts/${this.class_inst_id}/works/${this.class_work_id}`;
            let form_data = {
                mission : JSON.stringify(vm.mission_list),
                class_mission : JSON.stringify(vm.class_mission_list),
            };
            this.$c.io.put(url, form_data).then(function(response) {
                let data = response.data;
                if(data.success) {
                    vm.is_open = false;
                    if(vm.callback) { vm.callback(vm.form.mode); }
                }
            });
        },

    },
    
    beforeMount : function() {
    },
}
</script>


<style scope>
.mission .index {
    width:30px;
}
.mission .mission_type {
    width:110px;
    
}
.mission .button {
    text-align: right;
    width:176px;
}
</style>